import avt1 from '../images/author/author1.png'
import avt2 from '../images/author/author1.png'
import avt3 from '../images/author/author14.png'
import avt4 from '../images/author/author15.png'
import avt5 from '../images/author/author16.png'
import avt6 from '../images/author/author17.png'

import img1 from '../images/aspn/game-camp/slot/main_slot/918KISS.jpg'
import img2 from '../images/aspn/game-camp/slot/main_slot/ambslot.jpg'
import img3 from '../images/aspn/game-camp/slot/main_slot/bolebit.jpg'
import img4 from '../images/aspn/game-camp/slot/main_slot/c09 gaming.jpg'
import img5 from '../images/aspn/game-camp/slot/main_slot/dragon gaming.jpg'
import img6 from '../images/aspn/game-camp/slot/main_slot/fc fa chai.jpg'
import img7 from '../images/aspn/game-camp/slot/main_slot/GMW slots.jpg'
import img8 from '../images/aspn/game-camp/slot/main_slot/habanero.jpg'
import img9 from '../images/aspn/game-camp/slot/main_slot/hackaw gaming.jpg'
import img10 from '../images/aspn/game-camp/slot/main_slot/JILI.jpg'
import img11 from '../images/aspn/game-camp/slot/main_slot/JOKER.jpg'
import img12 from '../images/aspn/game-camp/slot/main_slot/ka gaming.jpg'
import img13 from '../images/aspn/game-camp/slot/main_slot/Mancala gaming.jpg'
import img14 from '../images/aspn/game-camp/slot/main_slot/manna play.jpg'
import img15 from '../images/aspn/game-camp/slot/main_slot/microgaming.jpg'
import img16 from '../images/aspn/game-camp/slot/main_slot/NAGA GAMING.jpg'
import img17 from '../images/aspn/game-camp/slot/main_slot/NETENT BETTER GAMING.jpg'
import img18 from '../images/aspn/game-camp/slot/main_slot/nolimit city.jpg'
import img19 from '../images/aspn/game-camp/slot/main_slot/octoplay.jpg'
import img20 from '../images/aspn/game-camp/slot/main_slot/PGSLOT.jpg'
import img21 from '../images/aspn/game-camp/slot/main_slot/PINTHON.jpg'
import img22 from '../images/aspn/game-camp/slot/main_slot/platstar.jpg'
import img23 from '../images/aspn/game-camp/slot/main_slot/play_n go.jpg'
import img24 from '../images/aspn/game-camp/slot/main_slot/PP SLOT.jpg'
import img25 from '../images/aspn/game-camp/slot/main_slot/red tiger.jpg'
import img26 from '../images/aspn/game-camp/slot/main_slot/rich88.jpg'
import img27 from '../images/aspn/game-camp/slot/main_slot/Simpleplay.jpg'
import img28 from '../images/aspn/game-camp/slot/main_slot/SLOTXO.jpg'
import img29 from '../images/aspn/game-camp/slot/main_slot/spadegaming.jpg'
import img30 from '../images/aspn/game-camp/slot/main_slot/spinix.jpg'
import img31 from '../images/aspn/game-camp/slot/main_slot/toptrend gaming.png'
import img32 from '../images/aspn/game-camp/slot/main_slot/UPG.jpg'
import img33 from '../images/aspn/game-camp/slot/main_slot/vibra gaming.jpg'
import img34 from '../images/aspn/game-camp/slot/main_slot/ygggddrasil.jpg'

const dataslotOnline = [
    {
        id: 1,
        img: img1,
        title: '918KISS',
        create: 'Allspin168',
        price: '',
        avt: avt1,
    },
    {
        id: 2,
        img: img2,
        title: 'ambslot',
        create: 'Allspin168',
        price: '',
        avt: avt2,
    },
    {
        id: 3,
        img: img3,
        title: 'bolebit',
        create: 'Allspin168',
        price: '',
        avt: avt3,
    },
    {
        id: 4,
        img: img4,
        title: 'c09 gaming',
        create: 'Allspin168',
        price: '',
        avt: avt4,
    },
    {
        id: 5,
        img: img5,
        title: 'dragon gaming',
        create: 'Allspin168',
        price: '',
        avt: avt5,
    },
    {
        id: 6,
        img: img6,
        title: 'fc fa chai',
        create: 'Allspin168',
        price: '',
        avt: avt6,
    },
    {
        id: 7,
        img: img7,
        title: 'GMW slots',
        create: 'Allspin168',
        price: '',
        avt: avt1,
    },
    {
        id: 8,
        img: img8,
        title: 'habanero',
        create: 'Allspin168',
        price: '',
        avt: avt2,
    },
    {
        id: 9,
        img: img9,
        title: 'hackaw gaming',
        create: 'Allspin168',
        price: '',
        avt: avt3,
    },
    {
        id: 10,
        img: img10,
        title: 'JILI',
        create: 'Allspin168',
        price: '',
        avt: avt4,
    },
    {
        id: 11,
        img: img11,
        title: 'JOKER',
        create: 'Allspin168',
        price: '',
        avt: avt5,
    },
    {
        id: 12,
        img: img12,
        title: 'ka gaming',
        create: 'Allspin168',
        price: '',
        avt: avt6,
    },
    {
        id: 13,
        img: img13,
        title: 'Mancala gaming',
        create: 'Allspin168',
        price: '',
        avt: avt5,
    },
    {
        id: 14,
        img: img14,
        title: 'manna play',
        create: 'Allspin168',
        price: '',
        avt: avt6,
    },
    {
        id: 15,
        img: img15,
        title: 'microgaming',
        create: 'Allspin168',
        price: '',
        avt: avt1,
    },
    {
        id: 16,
        img: img16,
        title: 'NAGA GAMING',
        create: 'Allspin168',
        price: '',
        avt: avt2,
    },
    {
        id: 17,
        img: img17,
        title: 'NETENT BETTER GAMING',
        create: 'Allspin168',
        price: '',
        avt: avt3,
    },
    {
        id: 18,
        img: img18,
        title: 'nolimit city',
        create: 'Allspin168',
        price: '',
        avt: avt4,
    },
    {
        id: 19,
        img: img19,
        title: 'octoplay',
        create: 'Allspin168',
        price: '',
        avt: avt5,
    },
    {
        id: 20,
        img: img20,
        title: 'PGSLOT',
        create: 'Allspin168',
        price: '',
        avt: avt6,
    },
    {
        id: 21,
        img: img21,
        title: 'PINTHON',
        create: 'Allspin168',
        price: '',
        avt: avt1,
    },
    {
        id: 22,
        img: img22,
        title: 'platstar',
        create: 'Allspin168',
        price: '',
        avt: avt2,
    },
    {
        id: 23,
        img: img23,
        title: 'play_n go',
        create: 'Allspin168',
        price: '',
        avt: avt3,
    },
    {
        id: 24,
        img: img24,
        title: 'PP SLOT',
        create: 'Allspin168',
        price: '',
        avt: avt4,
    },
    {
        id: 25,
        img: img25,
        title: 'red tiger',
        create: 'Allspin168',
        price: '',
        avt: avt5,
    },
    {
        id: 26,
        img: img26,
        title: 'rich88',
        create: 'Allspin168',
        price: '',
        avt: avt6,
    },
    {
        id: 27,
        img: img27,
        title: 'Simpleplay',
        create: 'Allspin168',
        price: '',
        avt: avt1,
    },
    {
        id: 28,
        img: img28,
        title: 'SLOTXO',
        create: 'Allspin168',
        price: '',
        avt: avt2,
    },
    {
        id: 29,
        img: img29,
        title: 'spadegaming',
        create: 'Allspin168',
        price: '',
        avt: avt3,
    },
    {
        id: 30,
        img: img30,
        title: 'spinix',
        create: 'Allspin168',
        price: '',
        avt: avt4,
    },
    {
        id: 31,
        img: img31,
        title: 'toptrend gaming',
        create: 'Allspin168',
        price: '',
        avt: avt5,
    },
    {
        id: 32,
        img: img32,
        title: 'UPG',
        create: 'Allspin168',
        price: '',
        avt: avt6,
    },
    {
        id: 33,
        img: img33,
        title: 'vibra gaming',
        create: 'Allspin168',
        price: '',
        avt: avt1,
    },
    {
        id: 34,
        img: img34,
        title: 'ygggddrasil',
        create: 'Allspin168',
        price: '',
        avt: avt6,
    },
]

export default dataslotOnline;
