import img1 from '../images/aspn/game-camp/slot/cateslot-PGSLOT.jpg'
import img2 from '../images/aspn/game-camp/slot/cateslot-PP-SLOT.jpg'
import img3 from '../images/aspn/game-camp/slot/cateslot-JOKER.jpg'
import img4 from '../images/aspn/game-camp/slot/cateslot-SLOTXO.jpg'
import img5 from '../images/aspn/game-camp/slot/cateslot-JILI.jpg'
import img6 from '../images/aspn/game-camp/slot/cateslot-spinix.jpg'
import img7 from '../images/aspn/game-camp/slot/cateslot-ambslot.jpg'
import img8 from '../images/aspn/game-camp/slot/cateslot-ygggddrasil.jpg'
import avt from '../images/author/authorpd14.png'
import avt1 from '../images/author/authorpd14.png'
import avt2 from '../images/author/authorpd15.png'
import avt3 from '../images/author/authorpd16.png'
const dataCamp = [
    {
        id: 1,
        img: img1,
        avt: avt,
        title: 'Giulia Glur #32',
        create: '@SolvadorDali',
        avt1: avt1,
        avt2: avt2,
        avt3: avt3,
    },
    {
        id: 2,
        img: img2,
        avt: avt,
        title: 'Giulia Glur #32',
        create: '@SolvadorDali',
        avt1: avt1,
        avt2: avt2,
        avt3: avt3,
    },
    {
        id: 3,
        img: img3,
        avt: avt,
        title: 'Archetype #588',
        create: '@SolvadorDali',
        avt1: avt1,
        avt2: avt2,
        avt3: avt3,
    },
    {
        id: 4,
        img: img4,
        avt: avt,
        title: '3DPunks #070',
        create: '@SolvadorDali',
        avt1: avt1,
        avt2: avt2,
        avt3: avt3,
    },
    {
        id: 5,
        img: img5,
        avt: avt,
        title: 'Angelic Nyan Cat',
        create: '@SolvadorDali',
        avt1: avt1,
        avt2: avt2,
        avt3: avt3,
    },
    {
        id: 6,
        img: img6,
        avt: avt,
        title: 'Sweet Baby #1',
        create: '@SolvadorDali',
        avt1: avt1,
        avt2: avt2,
        avt3: avt3,
    },
    {
        id: 7,
        img: img7,
        avt: avt,
        title: 'Sweet Baby #1',
        create: '@SolvadorDali',
        avt1: avt1,
        avt2: avt2,
        avt3: avt3,
    },
    {
        id: 8,
        img: img8,
        avt: avt,
        title: 'Sweet Baby #1',
        create: '@SolvadorDali',
        avt1: avt1,
        avt2: avt2,
        avt3: avt3,
    },

   
]

export default dataCamp;