import React , {useState} from 'react';
import PageTitle from '../components/pagetitle/PageTitle';
import Casino_Live from '../components/liveautions/Livecasino';
import img1 from '../assets/images/aspn/live-auction/casino.png'
import dataLivecasino from '../assets/fake-data/data-Livecasino';
import Faqs from './Faqs';
Casino.propTypes = {
    
};

function Casino(props) {
    const [modalShow, setModalShow] = useState(false);
    const [dataTab] = useState([
        {
            id: 1,
            title: 'สล็อตออนไลน์',
        },
        {
            id: 2,
            title: 'คาสิโนสด',
        },
        {
            id: 3,
            title: 'เดิมพันกีฬา',
        },
        {
            id: 4,
            title: 'หวยออนไลน์',
        },
    ]);

    return (
        <div className='page-liveauction'>
            {/* <PageTitle sub='ค่ายเกมทั้งหมด' title='คาสิโนสด' /> */}
            <section className="tf-baner-live-auction">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner-liver-auction-wrap">
                                <div className="content">
                                    <div className="heading">
                                        <h2 className="title">คาสิโนสด</h2>
                                    </div>
                                    <p className="sub-heading">คาสิโนออนไลน์ด้วยเกมที่หลากหลายและนวัตกรรม พร้อมโบนัสและโปรโมชั่นมากมาย ทุกคนมีโอกาสที่จะทดลองเล่นพนันในสไตล์ที่ชอบ ผ่าน Allspin168 ที่ทำให้การเล่นคาสิโนเป็นประสบการณ์ที่น่าจดจำ</p><br/>
                                </div>
                                <div className="image2">
                                    <img src={img1} alt="Allspin168" className="img2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Casino_Live data={dataLivecasino} />
            <Faqs></Faqs>
        </div>
    );
}

export default Casino;