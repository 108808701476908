import React from 'react';
import dataCategoryASPN from '../assets/fake-data/data-categoryASPN';
import dataASPN from '../assets/fake-data/data-aspn';
import dataCollection from '../assets/fake-data/data-collection';
import dataEvents from '../assets/fake-data/data-Events';
import dataCamp from '../assets/fake-data/data-game-camp';
import dataPromotion from '../assets/fake-data/data-promotion';
import BannerASPN from '../components/banner/BannerASPN';
import CategoryASPN from '../components/category/CategoryASPN';
import CollectionASPN from '../components/collection/CollectionASPN';
import Exgame from '../components/create/Exgame';
import CreateASPN from '../components/create/CreateASPN';
import Camp from '../components/explore/GameCamp';
import SpecialEvents from '../components/liveautions/SpecialEvents';
import BlogASPN from './BlogASPN';

HomeASPN.propTypes = {
    
};

function HomeASPN(props) {
    return (
        <div className='home-3'>

            <div id="page">

                <Exgame />

                <BannerASPN data={dataPromotion} />

                <CreateASPN data={dataASPN} />

                <CategoryASPN data={dataCategoryASPN} />

                <CollectionASPN data={dataCollection} />

                <Camp data={dataCamp} />

                <SpecialEvents data={dataEvents} />   

                <BlogASPN dara={BlogASPN}/>

            </div>
            
        </div>
    );
}

export default HomeASPN;