import React, { useState, useEffect } from "react";
import "./styles.scss";
import { Link } from "react-router-dom";

import logo from "../../assets/images/logo/logo.png";
import logodark from "../../assets/images/logo/aspn_logo.png";

import bank from "../../assets/images/aspn/bank.png";
import "./styles.scss";

function Footer(props) {
  // const [listSocial] = useState([
  //     {
  //         icon: 'icon-facebook-f',
  //         path: '#'
  //     },
  //     {
  //         icon: 'icon-instagram',
  //         path: '#'
  //     },
  //     {
  //         icon: 'icon-youtube',
  //         path: '#'
  //     },
  //     {
  //         icon: 'icon-twitter',
  //         path: '#'
  //     },
  // ])

  // const [isVisible, setIsVisible] = useState(false);

  // const scrollToTop = () => {
  //     window.scrollTo({
  //         top: 0,
  //         behavior: "smooth"
  //     });
  // };

  // useEffect(() => {
  //     const toggleVisibility = () => {
  //         if (window.pageYOffset > 500) {
  //             setIsVisible(true);
  //         } else {
  //             setIsVisible(false);
  //         }
  //     };

  //     window.addEventListener("scroll", toggleVisibility);

  //     return () => window.removeEventListener("scroll", toggleVisibility);
  // }, []);

  return (
    <div className="new-footer">
      <div className="topup">
        <div className="logo">
          <img
            id="logo_footer"
            src={bank}
            alt="Bank Image"
            className="logo-dark"
          />
          <br />
          <img
            id="logo_footer"
            className="logo-dark"
            src={logodark}
            alt="Allspin168"
          />
          <img
            id="logo_footer"
            className="logo-light"
            src={logo}
            alt="Allspin168"
          />
          <p className="content">
            เว็บตรงลิขสิทธ์แท้จากต่างประเทศรวบรวมค่ายดังจากทั่วทุกมุมโลก
            บริการระดับพรีเมี่ยม มาพร้อมกับระบบ ฝาก-ถอน อัตโนมัติ 15 วิ
          </p>
          <div className="mobile-contact">
          <ul className="social-item-container">
            <li className="social-item">
              <Link to="#">
                <i className="fab fa-twitter"></i>
              </Link>
            </li>
            <li className="social-item">
              <Link to="#">
                <i className="fab fa-facebook"></i>
              </Link>
            </li>
            <li className="social-item">
              <Link to="#">
                <i className="fab fa-telegram-plane"></i>
              </Link>
            </li>
            <li className="social-item">
              <Link to="#">
                <i className="fab fa-youtube"></i>
              </Link>
            </li>
            <li className="social-item">
              <Link to="#">
                <i className="icon-fl-tik-tok-2"></i>
              </Link>
            </li>
            <li className="social-item">
              <Link to="#">
                <i className="icon-fl-vt"></i>
              </Link>
            </li>
          </ul>
          <p className="copy-right">
            Copyright © 2024 Allspin168. All Rights Reserved.
          </p>
          </div>
        </div>
      </div>
      <div>
        <div className="game">
          <div className="game-type">
            <div className="title">สล็อต</div>
            <div>918KISS</div>
            <div>ambslot</div>
            <div>bolebit</div>
          </div>
          <div className="game-type">
            <div className="title">คาสิโน</div>
            <div>ae</div>
            <div>allbet</div>
            <div>b-live-yeebet</div>
          </div>
          <div className="game-type">
            <div className="title">เดิมพันกีฬา</div>
            <div>GOAT SPORT</div>
            <div>TFGAMING</div>
          </div>
          <div className="game-type">
            <div className="title">หวย</div>
            <div>HUAY</div>
          </div>
        </div>
        <div className="desktop-contact">
          <ul className="social-item-container">
            <li className="social-item">
              <Link to="#">
                <i className="fab fa-twitter"></i>
              </Link>
            </li>
            <li className="social-item">
              <Link to="#">
                <i className="fab fa-facebook"></i>
              </Link>
            </li>
            <li className="social-item">
              <Link to="#">
                <i className="fab fa-telegram-plane"></i>
              </Link>
            </li>
            <li className="social-item">
              <Link to="#">
                <i className="fab fa-youtube"></i>
              </Link>
            </li>
            <li className="social-item">
              <Link to="#">
                <i className="icon-fl-tik-tok-2"></i>
              </Link>
            </li>
            <li className="social-item">
              <Link to="#">
                <i className="icon-fl-vt"></i>
              </Link>
            </li>
          </ul>
          <p className="copy-right">
            Copyright © 2024 Allspin168. All Rights Reserved.
          </p>
        </div>
      </div>
      <div className="game">
        <div className="game-type">
          <div className="title">ติดต่อ</div>
          <div>ติดต่อแอดมิน คลิ๊ก!!! <br></br> ตลอด 24 ชั่วโมง</div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
