import img1 from '../images/aspn/Home-page-category/category-slot.png'
import img2 from '../images/aspn/Home-page-category/category-lottery.png'
import img3 from '../images/aspn/Home-page-category/category-casino.png'
import img4 from '../images/aspn/Home-page-category/category-sport.png'
const dataCategoryASPN = [
    {
        id: 1,
        img: img1,
        title: 'สล็อตออนไลน์',
    },
    {
        id: 2,
        img: img2,
        title: 'หวยออนไลน์',
    },
    {
        id: 3,
        img: img3,
        title: 'คาสิโนออนไลน์',
    },
    {
        id: 4,
        img: img4,
        title: 'เดิมพันกีฬา',
    },
]

export default dataCategoryASPN;