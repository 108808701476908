import { Link } from "react-router-dom";

const NavList = (props) => {
  return (
    <>
      {!props.isMoblie && (
        <div className={`nav-list ${props.isDropDown ? "with-arrow" : ""}`}>
          <Link to={props.targetPath}>
            <div>{props.word}</div>
          </Link>
          {props.isDropDown && (
            <ul className="dropdown-content">
              {props.ddContent.map((item, index) => (
                <Link to={item.targetPath}>
                  <li key={index}>{item.name}</li>
                </Link>
              ))}
            </ul>
          )}
        </div>
      )}
      {props.isMoblie && (
        <>
          <div
            className={`nav-list-mobile ${
              props.isDropDown ? "with-arrow" : ""
            }`}
          >
            <Link to={props.targetPath}>
              <div>{props.word}</div>
            </Link>
          </div>
          {props.isDropDown && (
            <ul className="mobile-dropdown-container">
              {props.ddContent.map((item, index) => (
                <Link to={item.targetPath}>
                  <li key={index} className="mobile-dropdown-content">{item.name}</li>
                </Link>
              ))}
            </ul>
          )}
        </>
      )}
    </>
  );
};

export default NavList;
