import React from 'react';



function Page404(props) {
    return (
404
    );
}

export default Page404;