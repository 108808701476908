import img1 from '../images/aspn/icon/icon01.png'
import img2 from '../images/aspn/icon/icon02.png'
import img3 from '../images/aspn/icon/icon03.png'
import img4 from '../images/aspn/icon/icon04.png'

const dataASPN = [
    {
        id: 1,
        img: img1,
        heading: 'Easy and Fast',
        text: 'สมัคร-ฝาก-ถอนรวดเร็ว ผ่านระบบอัตโนมัติ'
    },
    {
        id: 2,
        img: img2,
        heading: 'ALL-IN-ONE',
        text: 'รวบรวมทุกค่ายดังระดับโลกไว้ในที่เดียว'
    },
    {
        id: 3,
        img: img3,
        heading: 'Good Services',
        text: 'แอดมินบริการด้วยใจดูแลตลอด 24 ชั่วโมง'
    },
    {
        id: 4,
        img: img4,
        heading: 'Safe and Secure',
        text: 'มั่นคง ปลอดภัย การันตียอดหลักล้านก็ถอนได้'
    },


]

export default dataASPN;