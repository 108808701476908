import { Link } from "react-router-dom";
import logo from "../../assets/images/logo/logo_allspin.png";
import NavList from "./Navlist";
import { useEffect, useCallback } from "react";
import { useState } from "react";

const Navbar = () => {
  const [isFixed, setIsFixed] = useState(false);
  const [mobileNav, setMobileNav] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const promoDdContent = [
    {
      name: "โปรโมชั่น",
      targetPath: "/promotion",
    },
    {
      name: "กิจกรรมพิเศษ",
      targetPath: "/specials",
    },
  ];

  const toggleMobileNav = () => {
    setMobileNav((prev) => !prev);
  };

  return (
    <>
      <div className={`nav-container ${isFixed ? "fixed" : ""}`}>
        <div className="nav-list-container">
          <Link to="/">
            <img src={logo} alt="Logo" height="1" />
          </Link>
          <NavList word="สล็อตออนไลน์" targetPath="/slot" />
          <NavList word="คาสิโนสด" targetPath="/casinos" />
          <NavList word="เดิมพันกีฬา" targetPath="/sports" />
          <NavList word="หวยออนไลน์" targetPath="/lottos" />
          <NavList
            word="โปรโมชัน"
            isDropDown={true}
            ddContent={promoDdContent}
            targetPath="/promotion"
          />
          <NavList word="ติดต่อ" targetPath="/contract" />
          <Link to="https://allspin168.electrikora.com/?action=register">
            <div className="button1 tf-button style-5">สมัครสมาชิก</div>
          </Link>
          <Link to="https://allspin168.electrikora.com/">
            <div className="button2 tf-button style-5">เข้าสู่ระบบ</div>
          </Link>
          <div className="hamberger">
            <label for="check">
              <input type="checkbox" id="check" onChange={toggleMobileNav} />
              <span></span>
              <span></span>
              <span></span>
            </label>
          </div>
        </div>
      </div>
      <div
        className={`nav-container-mobile ${isFixed ? "fixed" : ""} ${
          mobileNav ? "open" : "" 
        }`}
      >
        <NavList word="หน้าหลัก" targetPath="/" isMoblie={true} />
        <NavList word="สล็อตออนไลน์" targetPath="/slot" isMoblie={true} />
        <NavList word="คาสิโนสด" targetPath="/casinos" isMoblie={true} />
        <NavList word="เดิมพันกีฬา" targetPath="/sports" isMoblie={true} />
        <NavList word="หวยออนไลน์" targetPath="/lottos" isMoblie={true} />
        <NavList
          word="โปรโมชัน"
          isDropDown={true}
          ddContent={promoDdContent}
          targetPath="/promotion"
          isMoblie={true}
        />
        <NavList word="ติดต่อ" targetPath="/contract" isMoblie={true} />
        <div className="button-list">
          <Link to="https://allspin168.electrikora.com/?action=register">
            <div className="button1 tf-button style-7">สมัครสมาชิก</div>
          </Link>
          <Link to="https://allspin168.electrikora.com/">
            <div className="button2 tf-button style-6">เข้าสู่ระบบ</div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Navbar;
