import img1 from '../images/aspn/Blog/blog1.png'
import img2 from '../images/aspn/Blog/blog2.png'
import img3 from '../images/aspn/Blog/blog3.png'
import img4 from '../images/aspn/Blog/blog4.png'

const dataBlogASPN = [
    {
        id: 1,
        img: img1,
        title: 'หมุนธรรมดาหรือหมุนออโต้?',
        cate: 'รายละเอียด',
        user: 'By SalvadorDali',
        time: 'Jan 10, 2024',
        text: 'Manual Spin หรือ Auto Spin แบบไหนดีกว่ากันหลาย ๆ ท่านคงเคยเกิดคำถามและข้อสงสัยเกี่ยวกับการหมุนสล็อตว่าการหมุนสล็อตด้วยมือ (Manual Spin) โดยใช้มือกดหมุนด้วยตัวเองทีละครั้งกับการใช้ออโต้สปิน (Auto Spin) ที่เป็นระบบหมุนอัตโนมัติของสล็อตออนไลน์ แบบไหนกันนะที่ดีกว่ากัน? ...'
    },
    {
        id: 2,
        img: img2,
        title: 'นักเดิมพันที่ดีควรมีคุณสมบัติอะไรบ้าง?',
        cate: 'รายละเอียด',
        user: 'By SalvadorDali',
        time: 'Jan 10, 2024',
        text: '	คุณสมบัติที่ดีของนักเดิมพัน เชื่อว่าทุกคนน่าจะรู้ดีอยู่แล้วว่าการเดิมพันเกมพนันออนไลน์อย่างเกมสล็อต จะต้องอาศัยทั้งดวง และเทคนิคการเล่น รวมถึงตัวผู้เล่นด้วย ซึ่งโดยจริง ๆ แล้วมันอาจจะไม่ยากอย่างที่คิด การเล่นเกมสล็อตให้ได้เงินดี ไม่มีข้อจำกัดอะไรมากมายขนาดนั้น ...'
    },
    {
        id: 3,
        img: img3,
        title: 'วิธีเอาชนะสล็อตออนไลน์ ทุกรูปแบบ',
        cate: 'รายละเอียด',
        user: 'By SalvadorDali',
        time: 'Jan 10, 2024',
        text: 'สูตรลับสำหรับการเอาชนะสล็อตออนไลน์ หากพูดถึงเกมสล็อตออนไลน์นั้นผู้เล่นหลาย ๆ คนคงจะเคยได้ยินและเคยเห็นหน้าตาการเล่น รวมไปถึงวิธีการเล่นกันมาบ้างแล้ว เพราะเกมสล็อตออนไลน์ถือว่าเป็นหนึ่งเกมออนไลน์ที่ผู้คนส่วนใหญ่นิยมเล่นกันเป็นจำนวนมาก และไม่ได้มีวิธีการเล่นที่ซับซ้อนและยุ่งยากมากสักเท่าไหร่อีกด้วย ...'
    },
    {
        id: 4,
        img: img4,
        title: 'คุณเป็นนักเดิมพันสล็อตสายไหน?',
        cate: 'รายละเอียด',
        user: 'By SalvadorDali',
        time: 'Jan 10, 2024',
        text: 'รสชาติ บรรยากาศ การบริการ แนะนำ ประเภทของสล็อตออนไลน์เครื่องเล่นสล็อตเป็นหนึ่งในเครื่องเล่นยอดนิยมประจำคาสิโนไม่ว่าจะเป็นคาสิโนแบบภาคพื้นหรือแบบออนไลน์ เนื่องมาจากความง่ายและสะดวกในการเข้าถึงวิธีการใช้งานเกมสล็อต ประเภทของสล็อตออนไลน์และเกมสล็อตถูกแบ่งแบบย่อยไปได้อีกเป็นสี่ประเภทหลัก ...'
    },
]

export default dataBlogASPN;