import React, { useState } from "react";
import "./style.scss";
import icon1 from "../../assets/images/icon/rain1.svg";
import icon2 from "../../assets/images/icon/rain2.svg";
import { Link } from "react-router-dom";
import CustomModal from "./CustomModal.jsx";

function PromoCard(props) {
  const [openModal, setOpenModal] = useState(false);

  const toggleModal = () => {
    setOpenModal((prevState) => !prevState);
    console.log(openModal);
    
  };

  return (
    <div className="promo-item">
      <div key={props.id}>
      {openModal && <CustomModal title={props.title} funcClose={toggleModal} img={props.img} desc={props.desc}/>}
        <div className="sc-product style" onClick={toggleModal}>
          <div className="top">
            {/* <Link to="" onClick={() => setModalShow(true)} className="tag">{idx.title}</Link> */}
            {/* to="https://lin.ee/gz5a3Ws" */}
            <Link className="tag">
              {props.title}
            </Link>
          </div>
          <div className="features">
            <div className="product-media">
              <img to="https://lin.ee/gz5a3Ws" src={props.img} alt="images" />
              {/* <img onClick={() => setModalShow(true)} src={idx.img} alt="images" /> */}
            </div>
            <div className="rain-drop1">
              <img src={icon1} alt="images" />
            </div>
            <div className="rain-drop2">
              <img src={icon2} alt="images" />
            </div>
          </div>
          <div className="bottom-style2">
            <div className="product-button">
              <Link to="https://lin.ee/gz5a3Ws" className="tf-button">
                รับโปรโมชัน
              </Link>
              {/* <Link to='' onClick={() => setModalShow(true)} className="tf-button">รับโปรโมชัน</Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PromoCard;
