import React , {useState} from 'react';
import PropTypes from 'prop-types';

import { Accordion } from 'react-bootstrap-accordion';
import { Link } from 'react-router-dom';
import data from '../assets/fake-data/data-hotpick'
import icon1 from '../assets/images/icon/rain1.svg'
import icon2 from '../assets/images/icon/rain2.svg'
import icon3 from '../assets/images/icon/ethe.svg'
import CardModal from '../components/layouts/CardModal';


Faqs.propTypes = {
    
};

function Faqs(props) {
    const [modalShow, setModalShow] = useState(false);

    const [dataFaq] = useState([
        {
            id: 1,
            title: 'เครดิตฟรีแชร์ 80 บาท ทำได้อย่างไร?',
            text : 'เลือกช่องทางที่ต้องการทำกิจกรรมเครดิตฟรี Line / Twitter / Facebook / Tiktok เพียงกดไลค์กดแชร์และรีวิวเพจง่ายๆไม่กี่ขั้นตอน ลองทำดูก่อนนะคะ หรือสอบถามเพิ่มเติมได้ที่น้องแอดมินได้เลยค่ะ'
        },
        {
            id: 2,
            title: 'ลิงก์เข้าสู่ระบบ / สมัครสมาชิก ?',
            text: 'Login : https://ctm.electrikora.com/?prefix=allspin168',
            text2: 'Register : https://ctm.electrikora.com/?prefix=allspin168&action=register'
        },
        {
            id: 3,
            title: 'กระเป๋าเครดิตฟรีมีไว้ทำอะไร?',
            text : `กระเป๋าเครดิตฟรีเป็นของขวัญจากทางเว็บ
            ที่มอบให้กับคุณลูกค้าที่ผ่านเงื่อนไขของโปรโมชั่นบางรายการ 
            เงื่อนไขการได้รับสามารถสอบถามเพิ่มเติมได้ที่น้องแอดมินเลยนะคะ`
        },
        {
            id: 4,
            title: 'ฝากเงินไม่เข้าทำอย่างไร?(กรณีเลขบัญชีคล้ายกับลูกค้าท่านอื่น)',
            text : `เครดิตไม่เข้าอัตโนมัติ เกิดจากเลขบัญชีลูกค้าคล้ายกับลูกค้าท่านอื่น
            จึงทำให้ระบบไม่ปรับเครดิตให้อัตโนมัติ 
            *หากเครดิตยังไม่เข้าภายใน 3-5 นาที ให้ส่งสลิปแจ้งแอดมินเพื่อดำเนินการให้เลยในทันทีนะคะ`
        },
        {
            id: 5,
            title: 'ยอดเสียกดรับไม่ได้เพราะอะไร?',
            text : `ยอดฝากไม่ตรงตามเงื่อนไขหรือมียอดถอนรวมมากกว่ารายการฝาก 
            ระบบจะนับยอดฝาก 100 บาทขึ้นไปต่อบิล แบบไม่รับโปรโมชั่น 
            กดรับได้ทุกวัน ตั้งแต่หลังเวลา 02.00 น. ของวันถัดไป`
        },
    ])
    return (
        <div>   
            <section className="tf-faq">
                <div className="tf-container">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="tf-heading style-5">
                                <h4 className="heading">คำถามที่พบบ่อย</h4>
                                <p className="sub-heading">Allspin168 มีแอดมินคอยดูแลและให้คำปรึกษาเกี่ยวกับการเดิมพัน เว็บตรงที่ดีที่สุดในตอนนี้</p>
                            </div>
                            <div className="tf-accordion">
                                {
                                    dataFaq.map(idx => (
                                        <Accordion key={idx.id} title={idx.title} className='tf-toggle-title h6'>
                                            <p className="toggle-content">{idx.text} </p>
                                            <p className="toggle-content">{idx.text2} </p>
                                        </Accordion>
                                    ))
                                }          
                            </div> 
                        </div>
                    </div>
                </div>
            </section >
        </div>
    );
}

export default Faqs;