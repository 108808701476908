import avt1 from '../images/author/author1.png'
import avt2 from '../images/author/author1.png'
import avt3 from '../images/author/author14.png'

import img1 from '../images/aspn/game-camp/sport/main_sport/GOAT SPORT.jpg'
import img2 from '../images/aspn/game-camp/sport/main_sport/TFGAMING.jpg'
import img3 from '../images/aspn/game-camp/sport/main_sport/TWAIN SPORT.jpg'

const dataSport = [
    {
        id: 1,
        img: img1,
        title: 'GOAT SPORT',
        create: 'Allspin168',
        price: '',
        avt: avt1,
    },
    {
        id: 2,
        img: img2,
        title: 'TFGAMING',
        create: 'Allspin168',
        price: '',
        avt: avt2,
    },
    {
        id: 3,
        img: img3,
        title: 'TWAIN SPORT',
        create: 'Allspin168',
        price: '',
        avt: avt3,
    },
];

export default dataSport;