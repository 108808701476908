import "./style.scss";
import closeButton from "./pics/cross-button.svg";

function CustomModal(props) {
  const handleClose = (event) => {
    event.stopPropagation();
    props.funcClose();
  };

  return (
    <div className="custom-modal-container" onClick={props.funcClose}>
      <div className="custom-modal-inside" onClick={props.funcClose}>
        <div className="btn-close" onClick={handleClose}>
          <img src={closeButton} className="x" />
        </div>
        <img src={props.img} className="promo-pic" />
        <div>
          <p className="title">{props.title}</p>
        </div>
        <div className="desc">{props.desc}</div>
        <div className="btn-promo-container">
          <div className="tf-button style-6">รับโปรโมชั่น</div>
        </div>
      </div>
    </div>
  );
}

export default CustomModal;
