import React from 'react';
import PropTypes from 'prop-types';
import PageTitle from '../components/pagetitle/PageTitle';
import Detail from '../components/promotions/detail';
import dataPromotion from '../assets/fake-data/data-promotion';

Promotion.propTypes = {
    
};

function Promotion(props) {
    return (
        <div className='page-promotion'>
            {/* <PageTitle sub='โปรโมชัน' title='โปรโมชัน' /> */}
            <Detail data={dataPromotion} />
        </div>
    );
}

export default Promotion;