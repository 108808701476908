import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./style.scss";
import Pro1Modal from "../layouts/Promotion1Model";
import { Dropdown } from "react-bootstrap";
import icon1 from "../../assets/images/icon/rain1.svg";
import icon2 from "../../assets/images/icon/rain2.svg";
import { Link } from "react-router-dom";
import PromoCard from "./PromoCard";

Detail.propTypes = {
  data: PropTypes.array,
};

function Detail(props) {
  const [modalShow, setModalShow] = useState(false);
  const { data } = props;
  const [dataTab] = useState([
    {
      id: 1,
      title: "โปรโมชัน",
      item: 0,
    },
    {
      id: 2,
      title: "กิจกรรมพิเศษ",
      item: 1,
      link:'/specials'
    },
  ]);

  return (
    <section className="tf-section tf-hot-pick tf-filter">
      <div className="tf-container">
        <div className="row ">
          <div className="col-md-12">
            <div className="tf-heading mb32 wow fadeInUp">
              <h4 className="heading"> </h4>
            </div>
          </div>
          <Tabs>
            <div className="promotion-tablist">
              <TabList>
                {dataTab.map((idx) => (
                  <a href={idx.link}>
                    <Tab key={idx.id}>{idx.title}</Tab>
                  </a>
                ))}
              </TabList>
            </div>
            {
              <div className="promo-container">
                {data.map((item) => {
                  return (
                    <PromoCard
                      id={item.id}
                      title={item.heading}
                      img={item.img}
                      desc={item.desc}
                    />
                  );
                })}
              </div>
            }
          </Tabs>
        </div>
      </div>
      <Pro1Modal show={modalShow} onHide={() => setModalShow(false)} />
    </section>
  );
}

export default Detail;
