import avt1 from '../images/author/author1.png'
import avt2 from '../images/author/author1.png'
import avt3 from '../images/author/author14.png'
import avt4 from '../images/author/author15.png'
import avt5 from '../images/author/author16.png'
import avt6 from '../images/author/author17.png'

import img1 from '../images/aspn/game-camp/casino/main_casino/ae.jpg'
import img2 from '../images/aspn/game-camp/casino/main_casino/allbet.jpg'
import img3 from '../images/aspn/game-camp/casino/main_casino/b live yeebet gaming.jpg'
import img4 from '../images/aspn/game-camp/casino/main_casino/beter live.jpg'
import img5 from '../images/aspn/game-camp/casino/main_casino/big gaming.jpg'
import img6 from '../images/aspn/game-camp/casino/main_casino/Dream Gaming.jpg'
import img7 from '../images/aspn/game-camp/casino/main_casino/EBET.jpg'
import img8 from '../images/aspn/game-camp/casino/main_casino/evolution gaming.jpg'
import img9 from '../images/aspn/game-camp/casino/main_casino/LUCKY STEAK.jpg'
import img10 from '../images/aspn/game-camp/casino/main_casino/microgaming.jpg'
import img11 from '../images/aspn/game-camp/casino/main_casino/PP SLOTV2.jpg'
import img12 from '../images/aspn/game-camp/casino/main_casino/pretty gaming.jpg'
import img13 from '../images/aspn/game-camp/casino/main_casino/sagaming.jpg'
import img14 from '../images/aspn/game-camp/casino/main_casino/vtbet.jpg'
import img15 from '../images/aspn/game-camp/casino/main_casino/wm casino.jpg'
import img16 from '../images/aspn/game-camp/casino/main_casino/xg.jpg'

const dataLivecasino = [
    {
        id: 1,
        img: img1,
        title: 'ae',
        create: 'Allspin168',
        price: '',
        avt: avt1,
    },
    {
        id: 2,
        img: img2,
        title: 'allbet',
        create: 'Allspin168',
        price: '',
        avt: avt2,
    },
    {
        id: 3,
        img: img3,
        title: 'b live yeebet gaming',
        create: 'Allspin168',
        price: '',
        avt: avt3,
    },
    {
        id: 4,
        img: img4,
        title: 'beter live',
        create: 'Allspin168',
        price: '',
        avt: avt4,
    },
    {
        id: 5,
        img: img5,
        title: 'big gaming',
        create: 'Allspin168',
        price: '',
        avt: avt5,
    },
    {
        id: 6,
        img: img6,
        title: 'Dream Gaming',
        create: 'Allspin168',
        price: '',
        avt: avt6,
    },
    {
        id: 7,
        img: img7,
        title: 'EBET',
        create: 'Allspin168',
        price: '',
        avt: avt1,
    },
    {
        id: 8,
        img: img8,
        title: 'evolution gaming',
        create: 'Allspin168',
        price: '',
        avt: avt2,
    },
    {
        id: 9,
        img: img9,
        title: 'LUCKY STEAK',
        create: 'Allspin168',
        price: '',
        avt: avt3,
    },
    {
        id: 10,
        img: img10,
        title: 'microgaming',
        create: 'Allspin168',
        price: '',
        avt: avt4,
    },
    {
        id: 11,
        img: img11,
        title: 'PP SLOTV2',
        create: 'Allspin168',
        price: '',
        avt: avt5,
    },
    {
        id: 12,
        img: img12,
        title: 'pretty gaming',
        create: 'Allspin168',
        price: '',
        avt: avt6,
    },
    {
        id: 13,
        img: img13,
        title: 'sagaming',
        create: 'Allspin168',
        price: '',
        avt: avt5,
    },
    {
        id: 14,
        img: img14,
        title: 'vtbet',
        create: 'Allspin168',
        price: '',
        avt: avt6,
    },
    {
        id: 15,
        img: img15,
        title: 'wm casino',
        create: 'Allspin168',
        price: '',
        avt: avt1,
    },
    {
        id: 16,
        img: img16,
        title: 'xg',
        create: 'Allspin168',
        price: '',
        avt: avt2,
    },
];

export default dataLivecasino;