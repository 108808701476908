import React , {useState} from 'react';
import Huay_Live from '../components/liveautions/Livehuay';
import dataHuay from '../assets/fake-data/data-Huay';
import PageTitle from '../components/pagetitle/PageTitle';
import Faqs from './Faqs';
import img1 from '../assets/images/aspn/live-auction/huay.png'

Huay.propTypes = {
    
};

function Huay(props) {
    const [modalShow, setModalShow] = useState(false);
    const [dataTab] = useState([
        {
            id: 1,
            title: 'สล็อตออนไลน์',
        },
        {
            id: 2,
            title: 'คาสิโนสด',
        },
        {
            id: 3,
            title: 'เดิมพันกีฬา',
        },
        {
            id: 4,
            title: 'หวยออนไลน์',
        },
    ]);

    return (
        <div className='page-liveauction'>
            {/* <PageTitle sub='ค่ายเกมทั้งหมด' title='หวยออนไลน์' /> */}
            <section className="tf-baner-live-auction">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner-liver-auction-wrap">
                                <div className="content">
                                    <div className="heading">
                                        <h2 className="title">หวยออนไลน์</h2>
                                    </div>
                                    <p className="sub-heading">การเล่นหวยออนไลน์ที่น่าตื่นเต้นและมีโอกาสได้รับรางวัลมากมาย ทำให้คุณได้ลุ้นโชคและสัมผัสบรรยากาศหวยในรูปแบบทันสมัยที่สะดวกสบายที่สุด</p>
                                </div>
                                <div className="image2">
                                    <img src={img1} alt="Allspin168" className="img2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Huay_Live data={dataHuay} />
            <Faqs></Faqs>
        </div>
    );
}

export default Huay;