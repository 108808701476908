

import img1 from '../images/aspn/specialEvents/special01.jpg'
import img2 from '../images/aspn/specialEvents/special02.jpg'
import img3 from '../images/aspn/specialEvents/special03.jpg'
import img4 from '../images/aspn/specialEvents/special04.jpg'
import img5 from '../images/aspn/specialEvents/special05.jpg'
import img6 from '../images/aspn/specialEvents/special06.jpg'
import img7 from '../images/aspn/specialEvents/special07.jpg'
import img8 from '../images/aspn/specialEvents/special08.jpg'
import img9 from '../images/aspn/specialEvents/special09.jpg'

import avt1 from '../images/author/author1.png'

const dataEvents = [
    {
        id: 1,
        img: img1,
        title: 'รับค่าคอมมิชชั่น 0.7%',
        create: 'Allspin168',
        price: '',
        avt: avt1,
    },
    {
        id: 2,
        img: img2,
        title: 'ระบบชวน AFFILIATE',
        create: 'Allspin168',
        price: '',
        avt: avt1,
    },
    {
        id: 3,
        img: img3,
        title: 'กงล้อลุ้นสิทธ์',
        create: 'Allspin168',
        price: '',
        avt: avt1,
    },
    {
        id: 4,
        img: img4,
        title: 'รับเครดิตฟรี 1000 บาท',
        create: 'Allspin168',
        price: '',
        avt: avt1,
    },
    {
        id: 5,
        img: img5,
        title: 'คืนยอดเสีย 10%',
        create: 'Allspin168',
        price: '',
        avt: avt1,
    },
    {
        id: 6,
        img: img6,
        title: 'ชวนเพื่อนรับ 20%',
        create: 'Allspin168',
        price: '',
        avt: avt1,
    },
    {
        id: 7,
        img: img7,
        title: 'ฝากติดต่อกัน รับ 300 บาท',
        create: 'Allspin168',
        price: '',
        avt: avt1,
    },
    {
        id: 8,
        img: img8,
        title: 'สะสมยอดถอนได้ 1,000 บาท',
        create: 'Allspin168',
        price: '',
        avt: avt1,
    },
    {
        id: 9,
        img: img9,
        title: 'เครดิตฟรี 1,200 บาท',
        create: 'Allspin168',
        price: '',
        avt: avt1,
    },
]

export default dataEvents;