

import img1 from '../images/collection/collection1.jpg'
import img2 from '../images/collection/collection2.jpg'
import img3 from '../images/collection/collection3.jpg'
import img4 from '../images/collection/collection4.jpg'
import img5 from '../images/collection/collection5.jpg'
import img6 from '../images/collection/collection6.jpg'
import img7 from '../images/collection/collection7.jpg'
import img8 from '../images/collection/collection8.jpg'
import img9 from '../images/collection/collection9.jpg'
import img10 from '../images/collection/collection10.jpg'
import img11 from '../images/collection/collection11.jpg'
import img12 from '../images/collection/collection12.jpg'
import img13 from '../images/aspn/gameCollection/PG/pgicon-Treasures of Aztec.png'
import img14 from '../images/aspn/gameCollection/PG/pgicon-Caishen Wins.png'
import img15 from '../images/aspn/gameCollection/PG/pgicon-Mahjong.png'
import img16 from '../images/aspn/gameCollection/PP/ppicon-Wild West Gold.png'
import img17 from '../images/aspn/gameCollection/PP/ppicon-Big Bass Bonanza.png'
import img18 from '../images/aspn/gameCollection/PP/ppicon-The Dog House.png'
import img19 from '../images/aspn/gameCollection/JILI/jiliicon-ROMA X.png'
import img20 from '../images/aspn/gameCollection/JILI/jiliicon-Charge Buffalo.png'
import img21 from '../images/aspn/gameCollection/JILI/jiliicon-Golden Empire.png'

import PG from '../images/aspn/gameCollection/PG/pgicon.png'
import PP from '../images/aspn/gameCollection/PP/ppicon.png'
import JILI from '../images/aspn/gameCollection/JILI/jiliicon.png'

const dataCollection = [
    {
        id: 1,
        img1: img1,
        img2: img2,
        img3: img3,
        avt: PG,
        name: 'Moonbirds',
        count: '26 Items',
        price : '33.2 wETH',
        pricesale: '$ 92,025'
    },
    {
        id: 2,
        img1: img4,
        img2: img5,
        img3: img6,
        avt: PG,
        name: 'Cassandra',
        count: '26 Items',
        price : '33.2 wETH',
        pricesale: '$ 92,025'
    },
    {
        id: 3,
        img1: img7,
        img2: img8,
        img3: img9,
        avt: PG,
        name: 'Quinn Herrera',
        count: '26 Items',
        price : '33.2 wETH',
        pricesale: '$ 92,025'
    },
    {
        id: 4,
        img1: img10,
        img2: img11,
        img3: img12,
        avt: PG,
        name: 'Humbert Watts',
        count: '26 Items',
        price : '33.2 wETH',
        pricesale: '$ 92,025'
    },


    {
        id: 5,
        img1: img13,
        img2: img14,
        img3: img15,
        avt: PG,
        title: 'Pocket Gamesoft',
        name: '@Allspin168',
        count: '26 Items',
        price : '33.2 wETH',
        pricesale: '$ 92,025'
    },
    {
        id: 6,
        img1: img16,
        img2: img17,
        img3: img18,
        avt: PP,
        title: 'Pragmatic Play',
        name: '@Allspin168',
        count: '26 Items',
        price : '33.2 wETH',
        pricesale: '$ 92,025'
    },
    {
        id: 7,
        img1: img19,
        img2: img20,
        img3: img21,
        avt: JILI,
        title: 'Jili',
        name: '@Allspin168',
        count: '26 Items',
        price : '33.2 wETH',
        pricesale: '$ 92,025'
    },
]

export default dataCollection;