const menus = [

    {
        id: 1,
        name: 'สล็อตออนไลน์',
        links: '/slot',
    },
    {
        id: 2,
        name: 'คาสิโนสด',
        links: '/casinos',
    },
    {
        id: 3,
        name: 'เดิมพันกีฬา',
        links: '/sports',
        namesub: [
            {
                id: 1,
                sub: 'หวยออนไลน์',
                links: '/lottos'
            },
        ]
    },
    {
        id: 4,
        name: 'โปรโมชัน',
        links: '/promotion',
        namesub: [
            {
                id: 1,
                sub: 'กิจกกรรมพิเศษ',
                links: '/specials'
            },
        ]
    },
    {
        id: 5,
        name: 'ติดต่อ',
        links: '/contract',
        namesub: [
            {
            id: 1,
            sub: 'Line@',
            links: 'https://line.me/R/ti/p/@903dwneb?oat_content=url'
            }
        ]
    },
    {
        id: 6,
        name: 'สมัครสมาชิก',
        links: 'https://allspin168.electrikora.com/?action=register',
        button: true,
    },
    {
        id: 7,
        name: 'เข้าสู่ระบบ',
        links: 'https://allspin168.electrikora.com/',
        button: true,
    },
]

export default menus;